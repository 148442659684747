import "./init-fancybox";
import $ from "jquery";
import Cookies from 'js-cookie';
import localize from "./localize";

(function () {
  'use strict';

  $('[data-privacy-popup-for]').on('click', function (event) {
    event.preventDefault();

    const platform = this.getAttribute('data-privacy-popup-for');

    const $privacyPopUp = $('<div class="privacy-popup">');

    const loadVideo = () => {
      $privacyPopUp.addClass('privacy-popup--has-iframe');
      $privacyPopUp.html(
        '<div class="privacy-popup__inner"><iframe class="privacy-popup__iframe" ' +
        this.getAttribute('data-iframe-attributes') +
        '></iframe></div>'
      );
      $privacyPopUp.find('iframe').attr('src', this.getAttribute('href') + '&autoplay=1');
    };

    if (Cookies.get(platform + 'TermsAccepted') === '1') {
      // If privacy terms were accepted by the user less than a year ago, then directly show video

      loadVideo();

    } else {
      // Otherwise, ask for permission

      let headline = localize('privacy.headline');
      let pleaseConfirm = localize('privacy.pleaseConfirm');
      let infoPlatform = localize('privacy.info' + platform);
      let linkPlatform = localize('privacy.link' + platform);
      let linkTextPlatform = localize('privacy.linkText' + platform);
      let linkText = localize('privacy.linkText');
      let accept = localize('privacy.accept');
      let reject = localize('privacy.reject');
      let rememberPlatform = localize('privacy.remember' + platform);

      $privacyPopUp.html(`
        <h3>${headline}</h3>
        <p>${pleaseConfirm}</p>
        <p>${infoPlatform}</p>
        <p>
          <a data-privacy-link-platform
            class="icon-link icon-link--external icon-link--beside-text-left"
            target="_blank"
            rel="noreferrer">${linkTextPlatform}</a>
        </p>
        <p>
          <a data-privacy-link
            class="icon-link icon-link--arrow-right icon-link--beside-text-left"
            target="_blank"
            rel="noreferrer">${linkText}</a>
        </p>
        <p>
          <button data-accept class="btn btn-primary my-1 me-2">${accept}</button>
          <button data-reject class="btn btn-outline-primary my-1 me-2">${reject}</button>
        </p>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="1" id="always-accept-button">
          <label class="form-check-label" for="always-accept-button">
            ${rememberPlatform}
          </label>
        </div>
      `);

      $privacyPopUp.find('[data-privacy-link-platform]').attr('href', linkPlatform);
      $privacyPopUp.find('[data-privacy-link]').attr('href', this.getAttribute('data-privacy-url'));

      $privacyPopUp.find('[data-reject]').on('click', (event) => {
        event.preventDefault();

        $.fancybox.close();
      });

      $privacyPopUp.find('[data-accept]').on('click', (event) => {
        event.preventDefault();

        if ($privacyPopUp.find('#always-accept-button').prop('checked') === true) {

          // Remember choice to accept terms (if the user has selected the checkbox for it).

          Cookies.set(
            platform + 'TermsAccepted',
            '1',
            { expires: 365, secure: true }
          );
        }

        loadVideo();
        $.fancybox.update();
      });
    }

    $.fancybox.open(
      $privacyPopUp,
      {
        loop: false,
        buttons: [
          'close'
        ],
        smallBtn: false
      }
    );
  });

})();
