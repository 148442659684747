import "./init-fancybox";
import $ from "jquery";

(function () {
  'use strict';

  let options = {
    loop: false,
    buttons: [
      'close'
    ],
    caption: function (instance, item) {
      let caption = $(this).data('caption') || '';

      if (caption.length > 0) {
        caption = '<div class="fancybox-caption-inner">' + caption + '</div>';
      }

      return caption;
    },
  };

  $('[data-fancybox]').fancybox(options);
}());
