import $ from 'jquery';

(function ($) {
  $('.table-responsive').each(function() {
    let $table = $(this);
    let $tableHead = $table.find('> thead');
    let $tableHeadRows = $tableHead.children();
    let $tableBodyRows = $table.find('> tbody > tr, > tr');

    // Highlight table columns if the header cell is hovered
    $tableHeadRows.children().each(function () {
      let $tableCell = $(this);
      let $cellsToHighlight = $tableBodyRows.children(':nth-child(' + ($tableCell.index() + 1) + ')');

      $tableCell.hover(
        () => $cellsToHighlight.addClass('table-responsive__cell--highlighted'),
        () => $cellsToHighlight.removeClass('table-responsive__cell--highlighted')
      );
    });

    // Adjust HTML markup for responsive table
    $tableHead.addClass('table-responsive__head');
    $tableBodyRows.addClass('table-responsive__row');

    $tableBodyRows.children().each(function() {
      let $tableCell = $(this);

      $tableCell.attr('role', this.tagName === 'TH' ? 'rowheader' : 'cell');
      $tableCell.addClass('table-responsive__cell');

      if ($tableCell.text().trim() === '') {
        $tableCell.addClass('table-responsive__cell--hidden');
      } else {
        let $headerCells = $tableHeadRows.children(':nth-child(' + ($tableCell.index() + 1) + ')');
        let headerText = $headerCells.map(function() {
            return $(this).text();
          }).get().filter(n => n !== '').join(', ').trim();

        if (headerText !== '') {
          $tableCell.prepend(
            $('<h5 aria-hidden="true" class="table-responsive__cell-heading"></h5>').text(headerText + ': ')
          );
        }
      }
    });
  });
})($);
