import $ from 'jquery';

/*
 * Focus handling
 */

(function ($) {
  'use strict';

  var mousedownAfterKeypress = true;

  // Show focus for keyboard navigation, hide otherwise

  document.body.parentElement.dataset.hidefocus = 1;

  $(document.body).on('keypress keydown', function () {
    mousedownAfterKeypress = false;
  }).on('mousedown', function () {
    mousedownAfterKeypress = true;
    document.body.parentElement.dataset.hidefocus = 1;
  }).on('focusin', function (event) {
    if (mousedownAfterKeypress === false) {
      delete document.body.parentElement.dataset.hidefocus;
    }
  });
})($);
