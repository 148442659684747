(function () {
  'use strict';

  class SvgInjector {
    constructor() {
      this.imageEl = document.querySelectorAll('img[src*=".svg"]');

      this.inject = this.inject.bind(this);
      this.handleImages();
    }

    handleImages() {
      const self = this;
      Array.from(this.imageEl).forEach(function (image, index, array) {
        if (image.getAttribute('src')) {
          self.inject(image);
        }
      });
    }

    inject(image) {
      let imageId = image.getAttribute('id');
      let imageClass = image.getAttribute('class');
      let imageSrc = image.getAttribute('src');
      let imageAlt = image.getAttribute('alt');
      let imageHeight = image.getAttribute('height');
      let imageWidth = image.getAttribute('width');
      let xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP');
      xhr.open('GET', imageSrc);
      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4 && xhr.status === 200) {
          let xml = xhr.responseXML;
          let node = xml.getElementsByTagName('svg')[0];
          let svg = 'undefined';

          if (typeof imageId !== 'undefined' && imageId !== null) {
            node.setAttribute('id', imageID);
          }

          if (typeof imageClass !== 'undefined' &&  imageClass !== null) {
            node.setAttribute('class', imageClass + ' svg svg--inlined');
          } else {
            node.setAttribute('class', 'svg svg--inlined');
          }

          if (typeof imageAlt !== 'undefined' && imageAlt !== '') {
            let titleNode = xml.createElement('title');
            titleNode.appendChild(xml.createTextNode(imageAlt));
            node.insertBefore(titleNode, node.childNodes[0]);
          }

          if (typeof imageHeight !== 'undefined'
            && imageHeight !== null
            && typeof imageWidth !== 'undefined'
            && imageWidth !== null
          ) {
            node.setAttribute('height', imageHeight);
            node.setAttribute('width', imageWidth);
          }

          // Remove any invalid XML tags as per http://validator.w3.org
          node.removeAttribute('xmlns:a');

          image.parentNode.appendChild(node);
          image.remove();
        }
      };
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      xhr.send();
    }
  }

  new SvgInjector();
})();
