(function () {
  let validate = (element) => {
    const elementForInvalidClass = element.closest('[data-validation-feedback]') ?? element;

    elementForInvalidClass.classList.toggle('is-invalid', !element.validity.valid);

    elementForInvalidClass.classList.toggle('is-invalid--required', element.validity.valueMissing);

    elementForInvalidClass.classList.toggle('is-invalid--length', element.validity.tooLong || element.validity.tooShort);

    elementForInvalidClass.classList.toggle('is-invalid--range', element.validity.rangeOverflow || element.validity.rangeUnderflow);

    elementForInvalidClass.classList.toggle('is-invalid--elementtype', element.validity.typeMismatch || element.validity.badInput || element.validity.patternMismatch || element.validity.stepMismatch);

    element.closest('.form-group')?.classList.add('was-validated', 'was-validated--client-side');
  }

  document.querySelectorAll('[data-custom-validation]').forEach((form) => {

    const elements = Array.from(form.elements);

    // Validate a form element if focus is lost or if the value is changed
    elements.forEach((element) => {
      element.addEventListener('blur', () => validate(element));
      element.addEventListener('input', () => validate(element));
      element.addEventListener('change', () => validate(element));
    });

    // Validate all form elements before submit
    form.addEventListener('submit', (event) => {
      elements.forEach((element) => validate(element));

      const invalidElement = form.querySelector('.is-invalid');

      if (invalidElement) {
        event.preventDefault();

        invalidElement.focus();

        // Ensure tooltip with client-side validation message is visible, and input field is fully visible

        const tooltip = invalidElement.parentElement.querySelector('.invalid-tooltip');
        const topPosition = tooltip.getBoundingClientRect().top - document.querySelector('.site-menu').clientHeight;

        if (topPosition < 0) {
          document.scrollingElement.scrollBy(0, topPosition - 3); // 3px margin
        }

        const bottomPosition = invalidElement.getBoundingClientRect().bottom;
        const containerHeight = document.scrollingElement.clientHeight;

        if (bottomPosition > containerHeight) {
          document.scrollingElement.scrollBy(0, bottomPosition - containerHeight + 3);  // 3px margin
        }
      }
    });
  });
})();
