import $ from "jquery";

import localize from "./localize";

(function () {
  'use strict';

  window.jQuery = $;
  require('@fancyapps/fancybox/dist/jquery.fancybox');

  let close = localize('close');
  let previous = localize('previous');
  let next = localize('next');

  $.extend($.fancybox.defaults.i18n, {
    en: {
      CLOSE: close,
      NEXT: next,
      PREV: previous
    },
    de: {
      CLOSE: close,
      NEXT: next,
      PREV: previous
    }
  });
}());
