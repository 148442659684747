import { reflow } from 'bootstrap/js/src/util';

// Set data attribute when it is opening
document.addEventListener(
  'show.bs.collapse',
  (event) => {
    event.target.setAttribute('data-is-visible', 'about-to-open');
    reflow(event.target); // Force a re-rendering to enable CSS transition.
    event.target.setAttribute('data-is-visible', 'opening'); // Start CSS transition
  }
);

// Set data attribute when the opening transition is complete
document.addEventListener(
  'shown.bs.collapse',
  (event) => event.target.setAttribute('data-is-visible', 'open')
);

// Set data attribute when it starts closing
document.addEventListener(
  'hide.bs.collapse',
  (event) => event.target.setAttribute('data-is-visible', 'closing')
);

// Remove data attribute after the closing transition has completed
document.addEventListener(
  'hidden.bs.collapse',
  (event) => event.target.removeAttribute('data-is-visible')
);
