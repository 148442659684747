import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
const blueTriangleSmall = document.querySelector('.home-page__triangle-blue--small');
const blueTriangleBig = document.querySelector('.home-page__triangle-blue--big');
const blueTriangleMiddle = document.querySelector('.home-page__triangle-blue--middle');

// If you need to debug the animation at a later point, add markers: true to the scrollTrigger object and (re-)build the frontend for some useful debugging output.

const animationBig = gsap.timeline({
  defaults: {
    delay: 1.5,
    duration: 6000
  },
  scrollTrigger: {
    trigger: ".home-page__triangles",
    start: "center bottom",
    end: "center top",
    scrub: true,
    onLeave: () => {
      blueTriangleBig.style.backgroundColor = 'var(--smallStepNine)';
    },
    onEnterBack: () => {
      blueTriangleBig.style.backgroundColor = 'var(--bigStepNine)';
    },
  }
});

const animationSmall = gsap.timeline({
  defaults: {
    delay: 1.8,
    duration: 6000
  },
  scrollTrigger: {
    trigger: ".home-page__triangles",
    start: "center bottom",
    end: "center top",
    scrub: true,
    onLeave: () => {
      blueTriangleSmall.style.backgroundColor = 'var(--smallStepNine)';
    },
    onEnterBack: () => {
      blueTriangleSmall.style.backgroundColor = 'var(--smallStepNine)';
    },
  }
});

const animationMiddle = gsap.timeline({
  defaults: {
    delay: 1.5,
    duration: 6000
  },
  scrollTrigger: {
    trigger: ".home-page__triangles",
    start: "center bottom",
    end: "center top",
    scrub: true,
    onEnter: () => {
      if (blueTriangleMiddle.classList.contains('home-page__triangles--hidden')) {
        blueTriangleMiddle.classList.remove('home-page__triangles--hidden');
      }
    },
    onLeave: () => {
      blueTriangleMiddle.style.backgroundColor = 'var(--hrkRed)';
    },
    onEnterBack: () => {
      blueTriangleMiddle.style.backgroundColor = 'var(--hrkRose)';
    },
    onLeaveBack: () => {
      if (!blueTriangleMiddle.classList.contains('home-page__triangles--hidden')) {
        blueTriangleMiddle.classList.add('home-page__triangles--hidden');
      }
    }
  }
});

if (window.innerWidth >= 767) {
  animationSmall.from(blueTriangleSmall, {x: 0, y: 0, scale: 1})
    .to(blueTriangleSmall, {x: 0, y: 0, backgroundColor: 'var(--smallStepOne)', scale: 1})
    .to(blueTriangleSmall, {x: 0, y: 0, backgroundColor: 'var(--smallStepTwo)', scale: 1})
    .to(blueTriangleSmall, {x: 225, y: 75, backgroundColor: 'var(--smallStepThree)', scale: 1.4})
    .to(blueTriangleSmall, {x: 325, y: 100, backgroundColor: 'var(--smallStepFour)', scale: 1.5})
    .to(blueTriangleSmall, {x: 425, y: 125, backgroundColor: 'var(--smallStepFive)', scale: 1.6})
    .to(blueTriangleSmall, {x: 525, y: 150, backgroundColor: 'var(--smallStepSix)', scale: 1.7})
    .to(blueTriangleSmall, {x: 625, y: 175, backgroundColor: 'var(--smallStepSeven)', scale: 1.8})
    .to(blueTriangleSmall, {x: 725, y: 200, backgroundColor: 'var(--smallStepEight)', scale: 1.9})
    .to(blueTriangleSmall, {
      x: 825,
      y: 235,
      backgroundColor: 'var(--smallStepNine)',
      scale: 2
    });
  animationBig.from(blueTriangleBig, {x: 0, y: 0, scale: 1})
    .to(blueTriangleBig, {x: 0, y: 0, backgroundColor: 'var(--bigStepOne)'})
    .to(blueTriangleBig, {x: 0, y: 0, backgroundColor: 'var(--bigStepTwo)'})
    .to(blueTriangleBig, {x: 300, y: 40, backgroundColor: 'var(--bigStepThree)'})
    .to(blueTriangleBig, {x: 400, y: 50, backgroundColor: 'var(--bigStepFour)'})
    .to(blueTriangleBig, {x: 500, y: 60, backgroundColor: 'var(--bigStepFive)'})
    .to(blueTriangleBig, {x: 600, y: 70, backgroundColor: 'var(--bigStepSix)'})
    .to(blueTriangleBig, {x: 700, y: 80, backgroundColor: 'var(--bigStepSeven)'})
    .to(blueTriangleBig, {x: 800, y: 90, backgroundColor: 'var(--bigStepEight)'})
    .to(blueTriangleBig, {
      x: 900,
      y: 100,
      backgroundColor: 'var(--bigStepNine)',
      scale: 1
    });
  animationMiddle.from(blueTriangleMiddle, {
    x: 0,
    y: 0,
    scale: 0,
    opacity: 0,
    zIndex: 0,
    right: 0
  })
    .to(blueTriangleMiddle, {
      x: 0,
      y: 0,
      backgroundColor: 'var(--overlappingStepOne)',
      zIndex: 2,
      opacity: 0.1,
      scale: 0.1,
      right: '1rem'
    })
    .to(blueTriangleMiddle, {
      x: 0,
      y: 0,
      backgroundColor: 'var(--overlappingStepOne)',
      zIndex: 2,
      opacity: 0.2,
      scale: 0.1,
      right: '1rem'
    })
    .to(blueTriangleMiddle, {
      x: 0,
      y: 0,
      backgroundColor: 'var(--overlappingStepTwo)',
      zIndex: 2,
      opacity: 0.1,
      scale: 0.4,
      right: '0.6rem'
    })
    .to(blueTriangleMiddle, {
      x: 0,
      y: 0,
      backgroundColor: 'var(--overlappingStepTwo)',
      zIndex: 2,
      opacity: 0.2,
      scale: 0.4,
      right: '0.7.6rem'
    })
    .to(blueTriangleMiddle, {
      x: 0,
      y: 0,
      backgroundColor: 'var(--overlappingStepThree)',
      zIndex: 2,
      opacity: 0.3,
      scale: 0.56,
      right: '0.2rem'
    })
    .to(blueTriangleMiddle, {
      x: 0,
      y: 0,
      backgroundColor: 'var(--overlappingStepFour)',
      zIndex: 2,
      opacity: 0.4,
      scale: 0.99,
      right: '-0.8rem'
    })
    .to(blueTriangleMiddle, {
      x: 0,
      y: 0,
      backgroundColor: 'var(--overlappingStepFive)',
      zIndex: 2,
      opacity: 0.5,
      scale: 1.2,
      right: '-1.3rem'
    })
    .to(blueTriangleMiddle, {
      x: 0,
      y: 0,
      backgroundColor: 'var(--overlappingStepSix)',
      zIndex: 2,
      opacity: 0.6,
      scale: 1.6,
      right: '-2.3rem'
    })
    .to(blueTriangleMiddle, {
      x: 0,
      y: 0,
      backgroundColor: 'var(--overlappingStepSeven)',
      zIndex: 2,
      opacity: 0.7,
      scale: 1.6,
      right: '-2.3rem'
    })
    .to(blueTriangleMiddle, {
      x: 0,
      y: 0,
      backgroundColor: 'var(--overlappingStepEight)',
      zIndex: 2,
      opacity: 0.8,
      scale: 1.6,
      right: '-1.9rem'
    })
    .to(blueTriangleMiddle, {
      x: 0,
      y: 0,
      backgroundColor: 'var(--overlappingStepNine)',
      scale: 1.8,
      opacity: 1,
      zIndex: 2,
      right: '-2.2rem',
      top: '1.1rem'
    });
} else {
  blueTriangleMiddle.style.right = '-1.9rem';

  animationSmall.from(blueTriangleSmall, {x: 0, y: 0, scale: 1})
    .to(blueTriangleSmall, {x: 10, y: 28, backgroundColor: 'var(--smallStepOne)', scale: 1.1})
    .to(blueTriangleSmall, {x: 20, y: 53, backgroundColor: 'var(--smallStepTwo)', scale: 1.2})
    .to(blueTriangleSmall, {x: 30, y: 78, backgroundColor: 'var(--smallStepThree)', scale: 1.3})
    .to(blueTriangleSmall, {x: 40, y: 103, backgroundColor: 'var(--smallStepFour)', scale: 1.4})
    .to(blueTriangleSmall, {x: 50, y: 128, backgroundColor: 'var(--smallStepFive)', scale: 1.4})
    .to(blueTriangleSmall, {x: 60, y: 153, backgroundColor: 'var(--smallStepSix)', scale: 1.5})
    .to(blueTriangleSmall, {x: 70, y: 178, backgroundColor: 'var(--smallStepSeven)', scale: 1.5})
    .to(blueTriangleSmall, {x: 80, y: 203, backgroundColor: 'var(--smallStepEight)', scale: 1.6})
    .to(blueTriangleSmall, {
      x: 85,
      y: 247,
      backgroundColor: 'var(--smallStepNine)',
      scale: 1.5
    });
  animationBig.from(blueTriangleBig, {x: 0, y: 0, scale: 1})
    .to(blueTriangleBig, {x: 50, y: 5, backgroundColor: 'var(--bigStepOne)'})
    .to(blueTriangleBig, {x: 60, y: 25, backgroundColor: 'var(--bigStepTwo)'})
    .to(blueTriangleBig, {x: 70, y: 50, backgroundColor: 'var(--bigStepThree)'})
    .to(blueTriangleBig, {x: 80, y: 75, backgroundColor: 'var(--bigStepFour)'})
    .to(blueTriangleBig, {x: 90, y: 100, backgroundColor: 'var(--bigStepFive)'})
    .to(blueTriangleBig, {x: 100, y: 125, backgroundColor: 'var(--bigStepSix)'})
    .to(blueTriangleBig, {x: 110, y: 150, backgroundColor: 'var(--bigStepSeven)'})
    .to(blueTriangleBig, {x: 120, y: 175, backgroundColor: 'var(--bigStepEight)'})
    .to(blueTriangleBig, {
      x: 130,
      y: 225,
      backgroundColor: 'var(--bigStepNine)',
      scale: 1
    });
  animationMiddle.from(blueTriangleMiddle, {x: 0, y: 0, scale: 1, opacity: 0, top: '1.5rem', right: '-1.9rem'})
    .to(blueTriangleMiddle, {x: 0, y: 0, backgroundColor: 'var(--overlappingStepThree)', opacity: 0.1, top: '1.5rem', right: '-1.9rem', scale: 1.1 })
    .to(blueTriangleMiddle, {x: 0, y: 0, backgroundColor: 'var(--overlappingStepThree)', opacity: 0.1, top: '1.5rem', right: '-1.9rem', scale: 1.2 })
    .to(blueTriangleMiddle, {x: 0, y: 0, backgroundColor: 'var(--overlappingStepThree)', opacity: 0.2, top: '1.5rem', right: '-1.9rem', scale: 1.2 })
    .to(blueTriangleMiddle, {x: 0, y: 0, backgroundColor: 'var(--overlappingStepFour)', opacity: 0.4, top: '1.5rem', right: '-1.9rem', scale: 1.4 })
    .to(blueTriangleMiddle, {x: 0, y: 0, backgroundColor: 'var(--overlappingStepFive)', opacity: 0.5, top: '1.5rem', right: '-1.9rem', scale: 1.4 })
    .to(blueTriangleMiddle, {x: 0, y: 0, backgroundColor: 'var(--overlappingStepSix)', opacity: 0.6, top: '1.5rem', right: '-1.9rem', scale: 1.5 })
    .to(blueTriangleMiddle, {x: 0, y: 0, backgroundColor: 'var(--overlappingStepSeven)', opacity: 0.8, top: '1.5rem', right: '-1.9rem', scale: 1.5 })
    .to(blueTriangleMiddle, {x: 0, y: 0, backgroundColor: 'var(--overlappingStepEight)', opacity: 0.9, top: '1.5rem', right: '-1.9rem', scale: 1.6 })
    .to(blueTriangleMiddle, {x: 0, y: 0, backgroundColor: 'var(--overlappingStepNine)', scale: 1.5, opacity: 1, right: '-1.58rem', top: '1rem'});
}
