import localize from "./localize";

(function () {
  'use strict';

  let newsCardLinks = document.querySelectorAll('.cards__link--news');

  let setOverflowClass = (link) => {
    link.classList.toggle(
      'cards__link--has-overflow',
      link.scrollHeight > link.parentElement.clientHeight
    );
  }

  let setButtonLabel = (link, text) => {
    let button = link.parentElement.querySelector('.cards__sizing-button');

    button.setAttribute('aria-label', text);
  }

  let shorten = (link) => {
    link.style.maxHeight = '';
    link.classList.remove('cards__link--show-complete');

    setButtonLabel(link, localize('newsCard.showComplete'));
    setOverflowClass(link);
  };

  let showComplete = (link) => {
    document.querySelectorAll('.cards__link--show-complete').forEach(
      (link2) => shorten(link2)
    );

    link.style.maxHeight = link.scrollHeight + 'px';
    link.classList.add('cards__link--show-complete');

    setButtonLabel(link, localize('newsCard.shorten'));
  };

  newsCardLinks.forEach((link, index) => {
    let id = 'news-card-link-' + index;
    let button = document.createElement('button');

    let isShownComplete = () => link.classList.contains('cards__link--show-complete');

    button.classList.add('cards__sizing-button');
    button.setAttribute('aria-controls', id);

    link.id = id;

    link.parentElement.appendChild(button);

    shorten(link);

    button.addEventListener(
      'click',
      (event) => {
        event.preventDefault();

        if (isShownComplete()) {
          shorten(link);
        } else {
          showComplete(link);
        }
      });
    }
  );

  window.addEventListener(
    "resize",
    () => {
      newsCardLinks.forEach((link) => {
        setOverflowClass(link)
      });
    }
  );
})();
