import { Dropdown } from 'bootstrap';
import { executeAfterTransition, reflow } from 'bootstrap/js/src/util';
import ScrollBarHelper from 'bootstrap/js/src/util/scrollbar';

const openedMenus = new Set();

const closeMenus = () => {
  openedMenus.forEach((menuElement) => {
    openedMenus.delete(menuElement);
    Dropdown.getInstance(menuElement)?.hide();
  });
}

const scrollBarHelper = new ScrollBarHelper();

const backdropElement = document.createElement('div');
backdropElement.className = 'site-menu__backdrop';
backdropElement.addEventListener('mousedown', closeMenus);
document.body.append(backdropElement);

document.querySelectorAll('.site-menu .menu').forEach((menuElement) => {
  menuElement.addEventListener(
    'show.bs.dropdown',
    (event) => {
      openedMenus.add(event.target);

      // Show backdrop if a (sub-)menu is opened
      if (!backdropElement.hasAttribute('data-is-visible')) {
        backdropElement.setAttribute('data-is-visible', 'about-to-open');
        scrollBarHelper.hide();
      }
      reflow(backdropElement);
      backdropElement.setAttribute('data-is-visible', 'opening');
      executeAfterTransition(
        () => {
          if (openedMenus.size > 0) {
            backdropElement.setAttribute('data-is-visible', 'open');
          }
        },
        backdropElement
      );
    }
  );

  menuElement.addEventListener(
    'shown.bs.dropdown',
    (event) => {
      const dropdownElement = event.target.closest('.dropdown');
      const dropDownMenu = dropdownElement.querySelector('.dropdown-menu');
      const boundingClientRect = dropdownElement.getBoundingClientRect();

      dropDownMenu.style.maxHeight = 'calc(100vh - ' + (boundingClientRect.top + boundingClientRect.height) + 'px)';
      dropDownMenu.style.overflowY = 'auto';
    }
  );

  menuElement.addEventListener(
    'hide.bs.dropdown',
    (event) => {
      openedMenus.delete(event.target);

      // Hide backdrop if menus are closed
      if (openedMenus.size === 0) {
        backdropElement.setAttribute('data-is-visible', 'closing');
        reflow(backdropElement);
        executeAfterTransition(
          () => {
            if (openedMenus.size === 0 && backdropElement.getAttribute('data-is-visible') === 'closing') {
              backdropElement.removeAttribute('data-is-visible');
              scrollBarHelper.reset();
            }
          },
          backdropElement
        );
      }
    }
  );
});

document.querySelector('.search-dropdown')?.addEventListener(
  'shown.bs.dropdown',
  () => document.getElementById('search_form_menu')?.focus()
);
